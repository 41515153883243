import { PayloadAction } from '@reduxjs/toolkit'
import { ErrorPayload } from 'astra-core/typings/api'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'
import { requestInitialState } from 'shared/lib/api'
import { getPreloadedState } from 'shared/lib/@reduxjs'

import {
  SearchProviderState,
  SetSearchMainPayload,
  MainSearchEntries
} from './types'

const REDUCER_KEY = 'searchProviderNew'

export const initialState: SearchProviderState = getPreloadedState(
  REDUCER_KEY,
  {
    main: {
      entries: {
        lineEvents: [],
        liveEvents: [],
        tournaments: []
      },
      fetchItems: requestInitialState
    }
  }
)

const searchProviderSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    fetchSearchMain(state, _action: PayloadAction<SetSearchMainPayload>) {
      state.main.fetchItems.error = null
      state.main.fetchItems.loading = true
      state.main.fetchItems.error = null
    },
    fetchSearchMainSuccess(
      state,
      { payload }: PayloadAction<MainSearchEntries>
    ) {
      state.main.entries = payload
      state.main.fetchItems.loading = false
      state.main.fetchItems.error = null
    },
    fetchSearchMainError(state, { payload }: PayloadAction<ErrorPayload>) {
      state.main.entries = {
        lineEvents: [],
        liveEvents: [],
        tournaments: []
      }
      state.main.fetchItems.loading = false
      state.main.fetchItems.error = payload
    }
  }
})

export const {
  actions: searchProviderActions,
  reducer,
  name: sliceKey
} = searchProviderSlice
