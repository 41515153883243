import { RootState } from 'shared/types/store'

import { initialState } from './slice'

const getSearchProviderState = (state: RootState) =>
  state.searchProviderNew || initialState

const selectMainSearchData = (state: RootState) =>
  getSearchProviderState(state).main

export const selectFetchMainItemsError = (state: RootState) =>
  selectMainSearchData(state).fetchItems.error

export const selectMainSearchDataEntries = (state: RootState) =>
  selectMainSearchData(state).entries
