import { RequestState } from 'astra-core/typings/api'
import { Event, Tournament } from 'betweb-openapi-axios'

export interface MainSearchEntries {
  lineEvents: Array<Event>
  liveEvents: Array<Event>
  tournaments: Array<Tournament>
}

export interface SearchProviderState {
  main: {
    fetchItems: RequestState
    entries: MainSearchEntries
  }
}

export type SetSearchMainPayload = {
  search: string
}

export enum SearchTabsType {
  Events = 'events',
  Tournaments = 'tournaments',
  All = 'all'
}
