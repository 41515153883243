import React, { useCallback } from 'react'
import { selectSettingsDataProp } from 'astra-core/containers/SettingsProvider'

import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { EColorsNames } from 'shared/types/theme'
import { EOnboardingType } from 'widgets/onboarding/Onboarding.types'
import { useAppSelector, useAppDispatch } from 'store'

import { StyledOnboardingNotification } from './OnboardingNotification.styled'

export const OnboardingNotification = () => {
  const dispatch = useAppDispatch()
  const isOnboardPassed = useAppSelector((state) =>
    selectSettingsDataProp(state, 'onboardPassed')
  )

  const onDisplayWelcomeOnboarding = useCallback(() => {
    dispatch(
      layoutContainerActions.setOnboarding({
        isDisplay: true,
        type: EOnboardingType.MAIN_PAGE_WELCOME
      })
    )
  }, [dispatch])

  return isOnboardPassed ? null : (
    <StyledOnboardingNotification
      colors={{ linkColor: { name: EColorsNames.Primary, value: 70 } }}
      linkText="mainPage tutorial link"
      modalWindowAction={onDisplayWelcomeOnboarding}
      text="mainPage tutorial text"
    />
  )
}
