import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FoundEntity } from 'betweb-openapi-axios'
import {
  selectFetchItemsError,
  selectInputSearch,
  selectSearchDataItems
} from 'astra-core/containers/SearchProvider'

import { useAppSelector } from 'store'
import { ClearResultSearch } from 'shared/ui/ClearResult'

import {
  StyledSearchItem,
  StyledSearchItemLive,
  StyledSearchList,
  StyledSearchListHeaderText
} from './SearchList.styled'

const Item = (item: FoundEntity) => {
  const link = useMemo(
    () =>
      item.live
        ? `/live/${item.sportId}/${item.eventId}`
        : `/line/${item.sportId}/${item.tournamentId}/${item.eventId}`,
    [item.eventId, item.live, item.sportId, item.tournamentId]
  )

  return (
    <StyledSearchItem to={link}>
      {item.name}
      {item.live ? <StyledSearchItemLive>LIVE</StyledSearchItemLive> : null}
    </StyledSearchItem>
  )
}

export const SearchList = () => {
  const [t] = useTranslation()

  const items = useAppSelector(selectSearchDataItems)
  const valueSearch = useAppSelector(selectInputSearch)
  const error = useAppSelector(selectFetchItemsError)

  if (!valueSearch?.length) return null

  if (!items.length) {
    if (!error) {
      return (
        <StyledSearchList>
          <ClearResultSearch />
        </StyledSearchList>
      )
    }
    return null
  }

  return (
    <StyledSearchList>
      <StyledSearchListHeaderText
        dangerouslySetInnerHTML={{
          __html: t('showing the most relevant result', { value: items.length })
        }}
      />
      {items.map((item) => (
        <Item key={item.eventId} {...item} />
      ))}
    </StyledSearchList>
  )
}
