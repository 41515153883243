import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  searchProviderActions,
  selectFetchItemsLoading
} from 'astra-core/containers/SearchProvider'
import { useHistory } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { ETestData } from 'shared/lib/testData'
import { IconSearch } from 'shared/ui/Icon/General/IconSearch'
import { EInputSize } from 'shared/ui/Input'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { EColorsNames } from 'shared/types/theme'
import { ERoutes } from 'shared/types/routes'
import {
  StyledIconRightButton,
  StyledSearch,
  StyledSearchInput
} from 'widgets/FullRegistration/Search/Search.styled'
import { EOnboardingType } from 'widgets/onboarding/Onboarding.types'
import { useOnboardingAttr } from 'widgets/onboarding/hooks'
import { EWelcomeOnboardingDataAttrs } from 'widgets/onboarding/typesOfOnboarding/welcomeOnboarding/welcomeOnboarding.types'
import { SearchList as SearchListNew } from 'pages/page-main/Main/components/Search/SearchListNew'
import { SearchList } from 'pages/page-main/Main/components/Search/SearchList'
import { useAppSelector, useAppDispatch } from 'store'
import { searchProviderActions as searchProviderActionsNew } from 'containers/Search'

import { StyledSearchWrapper, StyledWrapper } from './Search.styled'

export const Search = ({ initialValue = '' }) => {
  const [t] = useTranslation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const loading = useAppSelector(selectFetchItemsLoading)
  const { onboardingAttr: onboardingAttrFind } = useOnboardingAttr(
    EWelcomeOnboardingDataAttrs.INPUT_FIND,
    EOnboardingType.MAIN_PAGE_WELCOME
  )
  const newSearchEnabled = useFeatureFlag(EFeatureFlags.NEW_EVENTS_ENDPOINT)

  const [search, setSearch] = useState(initialValue)

  const debouncedChangeHandler = useDebouncedCallback((value: string) => {
    if (newSearchEnabled) {
      dispatch(searchProviderActionsNew.fetchSearchMain({ search: value }))
    } else {
      dispatch(searchProviderActions.setSearchMain({ valueSearch: value }))
    }
  }, 300)

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      setSearch(value)
      debouncedChangeHandler(value)
    },
    [debouncedChangeHandler]
  )

  const resetSearch = useCallback(() => {
    setSearch('')
    if (newSearchEnabled) {
      dispatch(searchProviderActionsNew.fetchSearchMain({ search: '' }))
    } else {
      dispatch(searchProviderActions.setSearchMain({ valueSearch: '' }))
    }
  }, [dispatch, newSearchEnabled])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      history.push(ERoutes.Search, { valueSearch: search })
    }
  }

  const searchWrapperBlock = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (search.length) {
      const outsideClickSearch = (e) => {
        if (!searchWrapperBlock.current?.contains(e.target)) {
          resetSearch()
        }
      }
      document.addEventListener('click', outsideClickSearch)
      return () => {
        document.removeEventListener('click', outsideClickSearch)
      }
    }
  }, [resetSearch, search])

  return (
    <StyledSearchWrapper>
      <StyledSearch {...onboardingAttrFind}>
        <StyledSearchInput
          customSize={EInputSize.L}
          data-test-id={ETestData.TestSearchInput}
          icon={IconSearch}
          placeholder={t('mainPage search placeholder')}
          rightPadding={28}
          value={search}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <StyledIconRightButton onClick={resetSearch}>
          {!!search.length &&
            (loading ? (
              <LoaderSpinner small />
            ) : (
              <IconClose
                colorProps={{ name: EColorsNames.Primary, value: 50 }}
                size={10}
              />
            ))}
        </StyledIconRightButton>
        <StyledWrapper ref={searchWrapperBlock}>
          {newSearchEnabled ? (
            <SearchListNew searchQuery={search} />
          ) : (
            <SearchList />
          )}
        </StyledWrapper>
      </StyledSearch>
    </StyledSearchWrapper>
  )
}
