import React from 'react'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store'
import { ClearResultSearch } from 'shared/ui/ClearResult'
import {
  selectMainSearchDataEntries,
  selectFetchMainItemsError
} from 'containers/Search'

import {
  StyledSearchList,
  StyledSearchListHeaderText
} from './SearchList.styled'
import { EventItem } from './EventItem'
import { TournamentItem } from './TournamentItem'

export const SearchList = ({ searchQuery }: { searchQuery: string }) => {
  const [t] = useTranslation()

  const entries = useAppSelector(selectMainSearchDataEntries)
  const error = useAppSelector(selectFetchMainItemsError)

  if (searchQuery.length < 3) return null

  const eventsLength = entries.liveEvents.length + entries.lineEvents.length

  const totalLength = eventsLength + entries.tournaments.length

  if (totalLength === 0) {
    if (!error) {
      return (
        <StyledSearchList>
          <ClearResultSearch />
        </StyledSearchList>
      )
    }
    return null
  }

  return (
    <StyledSearchList>
      <StyledSearchListHeaderText
        dangerouslySetInnerHTML={{
          __html: t('showing the most relevant result', { value: totalLength })
        }}
      />
      {entries.liveEvents.map((event) => (
        <EventItem event={event} key={event.id} />
      ))}
      {entries.lineEvents.map((event) => (
        <EventItem event={event} key={event.id} />
      ))}
      {entries.tournaments.map((tournament) => (
        <TournamentItem key={tournament.id} tournament={tournament} />
      ))}
    </StyledSearchList>
  )
}
