import React from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { Main } from './Main'

export const PageMain = () => {
  return (
    <Layout type={ELayoutsList.MAIN}>
      <Main />
    </Layout>
  )
}
