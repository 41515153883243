/* eslint-disable prefer-promise-reject-errors */

import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import {
  EventsApi,
  TournamentsApi,
  ActiveEventStatus,
  SearchQueryField,
  TournamentsApiGetTournamentsV2Request
} from 'betweb-openapi-axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { ApiManager } from 'astra-core/api'
import { getErrorOrUnknown } from 'astra-core/utils/errors'
import { selectCurrentLangCode } from 'astra-core/containers/LanguagesProvider/selectors'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { isFeatureFlagEnabledSaga } from 'astra-core/containers/ConfigProvider/saga'
import dayjs from 'dayjs'
import {
  DATE_FORMAT,
  EventsApiGetEventsV2Request
} from 'astra-core/containers/EventsProvider'

import { searchProviderActions } from './slice'
import { MainSearchEntries, SetSearchMainPayload } from './types'

const statuses = [
  ActiveEventStatus.Suspended,
  ActiveEventStatus.Trading,
  ActiveEventStatus.Planning,
  ActiveEventStatus.Open
].map((el) => el.replace('EVENT_', '')) as unknown as ActiveEventStatus[]

const isFulfilled = <T>(
  p: PromiseSettledResult<T>
): p is PromiseFulfilledResult<T> => p.status === 'fulfilled'

const mapResponses = (res) => {
  if (isFulfilled(res)) {
    return res.value
  } else {
    return res.reason
  }
}

function* fetchSearchMainSaga(action: PayloadAction<SetSearchMainPayload>) {
  try {
    const eventsApi = ApiManager.getApi(EventsApi)
    const tournamentsApi = ApiManager.getApi(TournamentsApi)
    const locale = yield select(selectCurrentLangCode)
    const query = action.payload.search
    const includeSubsports: boolean = yield call(
      isFeatureFlagEnabledSaga,
      EFeatureFlags.GENERIC_SPORT_ENABLED
    )

    if (query.length > 2) {
      const eventsRequest: EventsApiGetEventsV2Request = {
        locale,
        includeSubsports,
        statuses,
        pageSize: 20,
        searchQuery: query,
        searchQueryFields: [SearchQueryField.CompetitorName],
        sort: [
          'sportOrder.asc',
          'groupOrder.asc',
          'tournamentId.asc',
          'scheduledAt.asc'
        ]
      }
      const lineRequest: EventsApiGetEventsV2Request = {
        ...eventsRequest,
        live: false,
        scheduledFrom: dayjs().format(DATE_FORMAT),
        hasLineOutcomes: true
      }
      const liveRequest: EventsApiGetEventsV2Request = {
        ...eventsRequest,
        live: true,
        scheduledTo: dayjs().format(DATE_FORMAT)
      }
      const tournamentsRequest: TournamentsApiGetTournamentsV2Request = {
        locale,
        includeSubsports,
        eventStatuses: statuses,
        pageSize: 20,
        searchQuery: query,
        searchQueryFields: [SearchQueryField.TournamentName]
      }

      const linePromise = new Promise((resolve, reject) => {
        eventsApi
          .getEventsV2(lineRequest)
          .then(({ data }) => resolve({ data, entryKey: 'lineEvents' }))
          .catch(() => reject({ data: { items: [] }, entryKey: 'lineEvents' }))
      })
      const livePromise = new Promise((resolve, reject) => {
        eventsApi
          .getEventsV2(liveRequest)
          .then(({ data }) => resolve({ data, entryKey: 'liveEvents' }))
          .catch(() => reject({ data: { items: [] }, entryKey: 'liveEvents' }))
      })
      const tournamentsPromise = new Promise((resolve, reject) => {
        tournamentsApi
          .getTournamentsV2(tournamentsRequest)
          .then(({ data }) => resolve({ data, entryKey: 'tournaments' }))
          .catch(() => reject({ data: { items: [] }, entryKey: 'tournaments' }))
      })

      const result: MainSearchEntries = (yield Promise.allSettled([
        linePromise,
        livePromise,
        tournamentsPromise
      ]))
        .map(mapResponses)
        .reduce((acc, cur) => {
          acc[cur.entryKey] = cur.data.items
          return acc
        }, {})

      yield put(searchProviderActions.fetchSearchMainSuccess(result))
    }
  } catch (e) {
    const error = getErrorOrUnknown(e)
    yield put(searchProviderActions.fetchSearchMainError(error))
  }
}

export function* searchProviderSaga() {
  yield all([
    takeLatest(searchProviderActions.fetchSearchMain.type, fetchSearchMainSaga)
  ])
}
