import React, { useMemo } from 'react'
import { Event } from 'betweb-openapi-axios'
import { getEventTitleWithRenamedCompetitors } from 'astra-core'
import { getEventLink } from 'og-web-core/utils/events'

import { useCompetitorsRenamed } from 'hooks'

import { StyledSearchItem, StyledSearchItemLive } from './SearchList.styled'

export const EventItem = ({ event }: { event: Event }) => {
  const link = useMemo(() => getEventLink(event), [event])

  const { renameDate, isNewSbCompetitorsNameEnabled } = useCompetitorsRenamed()

  const eventTitle = useMemo(
    () =>
      getEventTitleWithRenamedCompetitors({
        event,
        renameDate,
        isNewSbCompetitorsNameEnabled
      }),
    [event, renameDate, isNewSbCompetitorsNameEnabled]
  )

  return (
    <StyledSearchItem to={link}>
      {eventTitle}
      {event.live ? <StyledSearchItemLive>LIVE</StyledSearchItemLive> : null}
    </StyledSearchItem>
  )
}
