import React from 'react'
import { useMainPageFetchData as ogCoreMainPageFetchData } from 'og-core/providers/main-page/useMainPageFetchData'
import loadable from '@loadable/component'
import { selectAccount } from 'astra-core/containers/AccountProvider/selectors'
import { selectIsQuickBetActive } from 'astra-core/containers/BetsProvider'

import { useAppSelector } from 'store'
import { EMainPageEventsList } from 'pages/page-main/Main/components/MainPageEvents/MainPageEvents.types'
import { BasketButtonSmallWindow } from 'widgets/right-container/Basket/BasketButtonSmallWindow'
import { BasketQuickBetButton } from 'widgets/right-container/Basket/BasketQuickBetButton/BasketQuickBetButton'

import { MainPageEvents, OnboardingNotification, Search } from './components'
import {
  StyledEventsSectionWrapper,
  StyledMainSearchBlockWrapper,
  StyledMainWrapper
} from './Main.styled'

const Banners = loadable(() => import('./components/Banners'), {
  resolveComponent: (c) => c.Banners,
  fallback: <></>
})

const Sponsors = loadable(() => import('./components/Sponsors'), {
  resolveComponent: (c) => c.Sponsors,
  fallback: <></>
})

export const Main = () => {
  ogCoreMainPageFetchData()

  return <MainPageContent />
}

const MainPageContent = () => {
  const isQuickBetActive = useAppSelector(selectIsQuickBetActive)
  const account = useAppSelector(selectAccount)

  const isQuickBetButtonVisible = isQuickBetActive && account

  return (
    <StyledMainWrapper>
      <StyledMainSearchBlockWrapper>
        <Search />

        {isQuickBetButtonVisible ? (
          <BasketQuickBetButton />
        ) : (
          <BasketButtonSmallWindow />
        )}
      </StyledMainSearchBlockWrapper>

      <OnboardingNotification />

      <Banners />

      <Sponsors />

      <StyledEventsSectionWrapper>
        <MainPageEvents mainPageEventsListType={EMainPageEventsList.TOP_LIVE} />
        <MainPageEvents mainPageEventsListType={EMainPageEventsList.TOP_LINE} />
        <MainPageEvents mainPageEventsListType={EMainPageEventsList.UPCOMING} />
      </StyledEventsSectionWrapper>
    </StyledMainWrapper>
  )
}
